<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    ({ props }) => (
        props.filled
            ? <svg viewBox="0 0 14 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.50206 0C8.60196 0 7.74694 0.270666 7.00823 0.745009C6.26957 0.248637 5.41547 0 4.5144 0C2.80619 0 1.28195 0.925753 0.519788 2.41482C-0.427226 4.2434 -0.0574342 6.4545 1.44334 7.92158L6.36151 12.7293C6.5464 12.9101 6.77729 13 7.00818 13C7.26253 13 7.49342 12.9101 7.65486 12.7293L12.573 7.92158C14.0503 6.47743 14.4201 4.26538 13.4966 2.41482C12.7345 0.902776 11.2103 0 9.50206 0Z"
                />
            </svg>
            : <svg viewBox="0 0 14 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.50206 0C8.60196 0 7.74694 0.270666 7.00823 0.745009C6.26957 0.248637 5.41547 0 4.5144 0C2.80619 0 1.28195 0.925753 0.519788 2.41482C-0.427226 4.2434 -0.0574342 6.4545 1.44334 7.92158L6.36151 12.7293C6.5464 12.9101 6.77729 13 7.00818 13C7.26253 13 7.49342 12.9101 7.65486 12.7293L12.573 7.92158C14.0503 6.47743 14.4201 4.26538 13.4966 2.41482C12.7345 0.902776 11.2103 0 9.50206 0ZM6.84684 2.0534C6.93883 2.14333 7.07773 2.14333 7.16971 2.0534C7.93183 1.30839 8.97083 0.992765 10.0323 1.15058C11.0947 1.3084 11.9714 1.96348 12.4566 2.88833C13.1727 4.28752 12.8949 5.98027 11.7639 7.0869L7.00809 11.7369L2.25132 7.10992C1.12033 6.00433 0.842525 4.31163 1.55865 2.91135C2.04389 1.96357 2.92053 1.33141 3.98298 1.1736C4.16787 1.15068 4.35278 1.12864 4.51421 1.12864C5.39178 1.12864 6.22351 1.44415 6.84684 2.0534Z"
                />
            </svg>
    )
)
</script>
